import { nodeInstance } from "@/plugins/axios";

const certificateTemplateService = {};

certificateTemplateService.getById = id => {
  return nodeInstance.get(`/api/certificateTemplate/${id}`);
};

certificateTemplateService.certificateTemplateByInstitution = institutionId => {
  return nodeInstance.get(`/api/certificateTemplate/institution/${institutionId}`);
};

certificateTemplateService.getCertificateTemplateByCourse = courseId => {
  return nodeInstance.get(`/api/certificateTemplateCourse/course/${courseId}`);
};

certificateTemplateService.getAll = () => {
  return nodeInstance.get(`/api/certificateTemplate`);
}
certificateTemplateService.createCertificateTemplateCourse = (data) => {
  return nodeInstance.post(`/api/certificateTemplateCourse`, data);
}

certificateTemplateService.updateCertificateTemplateCourse = (data,id) => {
  return nodeInstance.patch(`/api/certificateTemplateCourse/${id}`, data);
}
certificateTemplateService.create = (data) => {
  return nodeInstance.post(`/api/certificateTemplate`, data);
}

certificateTemplateService.update = (data) => {
  return nodeInstance.patch(`/api/certificateTemplate/${data.id}`, data.body);
}

certificateTemplateService.delete = (id) => {
  return nodeInstance.delete(`/api/certificateTemplate/${id}`);
}

export default certificateTemplateService;

<template src="./CertificateTemplateEditor.html"></template>

<script>
import grapesjs from "grapesjs";
require("grapesjs/dist/css/grapes.min.css");
require("grapesjs-mjml");
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs/dist/grapes.min.js";
import "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css";
import "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js";
import idiService from "@/services/Idi";
import certificateTemplateService from "@/services/CertificateTemplate";

export default {
  name: "CertificateTemplateEditor",
  data() {
    return {
      id: this.$route.query.id,
      institutions: [],
      lvlAcademics: [
        {
          id: "continuingEducation",
          name: "Educación Continua",
        },
        {
          id: "graduates",
          name: "Diplomados",
        },
        {
          id: "postGraduate",
          name: "Post grado",
        },
        {
          id: "undergraduate",
          name: "Pre grado",
        },
      ],
      certificate: {
        name: "",
        institution: null,
        lvlAcademic: null,
        duration: null,
      },
      html: null
    };
  },
  created() {
    this.getListInstitutions();
  },
  mounted() {
    this.editor = grapesjs.init({
      container: "#gjs",
      height: "900px",
      width: "100%",
      fromElement: true,
      plugins: ["gjs-preset-webpage", this.myPlugin],
      canvas: {
        styles: ["bundle1.css"],
      },
      storageManager: {
        id: "gjs-",
        type: "local",
        autosave: true,
        storeComponents: true,
        storeStyles: true,
        storeHtml: true,
        storeCss: true,
      },
      deviceManager: {
        devices: [
          {
            id: "desktop",
            name: "Desktop",
            width: "",
          },
          {
            id: "tablet",
            name: "Tablet",
            width: "768px",
            widthMedia: "992px",
          },
          {
            id: "mobilePortrait",
            name: "Mobile portrait",
            width: "320px",
            widthMedia: "575px",
          },
        ],
      },
      pluginsOpts: {
        "grapesjs-preset-webpage": {
          blocksBasicOpts: {
            blocks: [
              "column1",
              "column2",
              "column3",
              "column3-7",
              "text",
              "link",
              "image",
              "video",
            ],
            flexGrid: 1,
          },
          blocks: ["link-block", "quote", "text-basic"],
        },
      },
    });
    if (!this.id) {
      this.editor.CssComposer.clear();
      this.editor.setComponents("");
      this.editor.setStyle("");
    }
    if (this.$route.query.id) {
      this.certificateTemplateGetData();
    }
  },
  methods: {
    async save() {
      var html = this.editor.getHtml().toString();
      var css = this.editor.getCss().toString();
      const data = {
        json: {
          html: html,
          css: css
        },
        name: this.certificate.name,
        lvlAcademic: this.certificate.lvlAcademic,
        institutionId: this.certificate.institution,
        duration: parseInt(this.certificate.duration),
      };
      if (this.$route.query.id) {
        try {
          await certificateTemplateService.update({
            id: this.id,
            body: data,
          });
        } catch (error) {
          console.error(error);
          this.$buefy.toast.open({
            duration: 5000,
            message: error.response?.data?.message ?? "Se presentó un error",
            position: "is-bottom",
            type: "is-danger",
          });
          return null;
        }
      } else {
        try {
          await certificateTemplateService.create(data);
        } catch (error) {
          console.error(error);
          this.$buefy.toast.open({
            duration: 5000,
            message: error.response?.data?.message ?? "Se presentó un error",
            position: "is-bottom",
            type: "is-danger",
          });
          return null;
        }
      }
      this.$router.push("/certificateTemplate");
    },
    async certificateTemplateGetData() {
      this.certificateTemplate = await certificateTemplateService.getById(this.id);
      this.certificate.name = this.certificateTemplate.name;
      this.certificate.institution = this.certificateTemplate.institutionId;
      this.certificate.lvlAcademic = this.certificateTemplate.lvlAcademic;
      this.certificate.duration = this.certificateTemplate.duration;
      this.editor.CssComposer.clear();
      this.editor.setComponents(this.certificateTemplate.json.html);
      this.editor.setStyle(this.certificateTemplate.json.css);
    },
    async getListInstitutions() {
      try {
        this.institutions = await idiService.listInstitutions();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: ChooseUniversity/getListInstitutions()", e);
      }
    }
  },
};
</script>

<style lang="scss" scoped src="./CertificateTemplateEditor.scss">

</style>
